import React, {FC, useState, useCallback, useContext} from 'react';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import safeNavigate from '../../utils/safeNavigate';
import {UserContext, ServiceForm} from '../../porter-package';
import AccountContainer from '../../containers/AccountContainer';

const Account: FC = () => {
	const context = useContext(UserContext);
	if (!context.isLoggedIn) {
		safeNavigate('/log-in/');
		return null;
	}

	const [service, setService] = useState<boolean | undefined>(undefined);
	const handleServiceComplete = useCallback(
		(result) => {
			setService(result);
		},
		[service, setService]
	);

	return <AccountContainer title="Subscription">
		<Card>
			<Card.Body>
				<Card.Title>
					My Account &gt; Subscription
				</Card.Title>
				<Card.Subtitle className="mb-2 text-muted">
					{
						service === false ?
							'Please select a service plan.' :
							service === true ?
								'Thank you for using BeamFire.' :
								'Loading ...'
					}
					<p>
						<small>
							<a
								target="_blank"
								href="/#pricing">
								See all plans here
							</a>
						</small>
					</p>
				</Card.Subtitle>
				<Card.Text>
					{
						service === undefined ?
							<Spinner
								animation="border"
								role="status" /> :
							null
					}
					<ServiceForm onComplete={handleServiceComplete} />
				</Card.Text>
			</Card.Body>
		</Card>
	</AccountContainer>;
};

export default Account;
